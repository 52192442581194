import { Injectable } from '@angular/core';
import { global } from '../../global/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    public url:string = '';
    public identity:any;
    public token:any;

  constructor(
      public _http: HttpClient
  ) {
      this.url = global.url + global.api;
  }

    public madeLogin(user:any, gettoken = null): Observable<any>
    {
        if(gettoken != null)
        {
            user.gettoken = 'true';
        }
        let json = JSON.stringify(user);
        let params = 'json='+json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'login', params, {headers:headers});
    }

    public getIdentity()
    {

        let identity = JSON.parse(localStorage.getItem("identity"));

        if(identity != "undefined")
        {
            this.identity = identity;
        }
        else
        {
            this.identity = null;
        }

        return this.identity;
    }

    public getToken()
    {
        let token = localStorage.getItem("token");
        if(token != "undefined")
        {
            this.token = token;
        }
        else
        {
            this.token = null;
        }

        return this.token;
    }
}
