import { Injectable } from '@angular/core';
import { global } from '../../../global/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormularioService {

    public url:string = '';
    public password:any;
    public decrypted:any;

  constructor(
      public _http: HttpClient
  ) {
      this.url = global.url + global.api;
      this.decrypted = this.converseThisShit();
  }

    public sendData(data:any): Observable<any>
    {
        let json = JSON.stringify(data);
        let params = 'json='+json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url+'formulario', params, {headers:headers});
    }

    public converseThisShit():String
    {
        let value = localStorage.getItem('sub');
        let decrypt = atob(value).split(';');
        return decrypt[0];
    }

    public updateData(id:any, data:any): Observable<any>
    {
        let json = JSON.stringify(data);
        let params = 'json='+json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.put(this.url+'formulario/' + id, params, {headers:headers});
    }

    public getData(id:any): Observable<any>
    {
        return this._http.get(this.url + 'formulario/' +id);
    }

    public getAllData(): Observable<any>
    {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.get(this.url + 'formulario', {headers: headers});
    }

    public getAllDataByPage(page:number): Observable<any>
    {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('id', this.decrypted);
        return this._http.get(this.url + 'formulario?page='+page, {headers: headers});
    }

    public getCount(): Observable<any>
    {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('id', this.decrypted);
        return this._http.get(this.url + 'formulariocount', {headers: headers});
    }
}
