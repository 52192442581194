import { Injectable } from '@angular/core';
import { global } from '../../../global/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

    public url:string = '';

  constructor(
      public _http: HttpClient
  ) {
      this.url = global.url + global.api;
  }

    public getDepartamentos(): Observable<any>
    {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.get(this.url + 'departamento', {headers: headers});
    }

}
